<template>
  <div class="tab-pane show active">
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col-xl-6">
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between mb-5">
            <span>{{ $t('label.name') }}:</span>
            <span class="text-right font-weight-bolder">{{ user.name }}</span>
          </div>
          <div class="d-flex justify-content-between mb-5">
            <span>{{ $t('label.last_name') }}:</span>
            <span class="text-right font-weight-bolder">{{ user.last_name }}</span>
          </div>
          <div class="d-flex justify-content-between mb-5">
            <span>{{ $t('label.email') }}:</span>
            <span class="text-right font-weight-bolder">{{ user.email }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>{{ $t('label.phone_number') }}:</span>
            <span class="text-right font-weight-bolder">{{ user.phone_number }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputFormGroup from '@/components/elements/form-groups/InputFormGroup';

export default {
  components: {
    InputFormGroup
  },
  props: {
    user: {
      default: null
    }
  },
}
</script>