<template>
  <div class="tab-pane show active">
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Row-->
      <div class="row">
        <div class="col-xl-2"></div>
        <div class="col-xl-7">
          <InputFormGroup
              :label="$t('label.current_password')"
              :placeholder="$t('placeholder.enter_current_password')"
              :value="form.current_password"
              input-type="password"
              required
              :with-error="error.current_password"
              @input="form.current_password = $event"
          />
          <InputFormGroup
              :label="$t('label.new_password')"
              :placeholder="$t('placeholder.enter_new_password')"
              :value="form.new_password"
              input-type="password"
              required
              :with-error="error.new_password"
              :error-message="errorMessage"
              :help-message="`${$t('help.min_length_is')} 6`"
              @input="form.new_password = $event"
          />
          <InputFormGroup
              class="mb-0"
              :label="$t('label.confirm_password')"
              :placeholder="$t('placeholder.enter_new_password_one_more_time')"
              :value="confirmedPassword"
              input-type="password"
              required
              :with-error="error.new_password"
              :error-message="errorMessage"
              :help-message="`${$t('help.min_length_is')} 6`"
              @input="confirmedPassword = $event"
          />
        </div>
      </div>
      <!--end::Row-->
    </div>
    <!--end::Body-->
    <div class="card-footer d-flex justify-content-end">
      <button type="button" class="btn btn-outline-danger font-weight-bold mr-3" @click="clearForm">
        {{ $t('btn.cancel') }}
      </button>
      <button type="button"
              class="btn btn-success font-weight-bold"
              @click="submitForm"
      >
        {{ $t('btn.change_password') }}
      </button>
    </div>
  </div>
</template>

<script>
import InputFormGroup from '@/components/elements/form-groups/InputFormGroup';

export default {
  components: {
    InputFormGroup
  },
  data: () => ({
    confirmedPassword: '',
    errorMessage: null,
    form: {
      current_password: '',
      new_password: '',
    },
    error: {
      current_password: false,
      new_password: false
    },
  }),
  methods: {
    clearForm() {
      this.form.current_password = null;
      this.form.new_password = null;
      this.confirmedPassword = null;
    },
    submitForm() {
      if (!this.validateForm()) return false;
      this.$store.dispatch('usersStore/CHANGE_PASSWORD', this.form);
    },
    validateForm() {
      this.error.current_password = !this.form.current_password;
      this.error.new_password = !this.form.new_password
          || !this.confirmedPassword
          || this.form.new_password.length < 6
          || !this.passwordsIdentical();


      if (!this.passwordsIdentical()) {
        this.errorMessage = this.$t('error.passwords_not_the_same')
      } else if (this.form.new_password && this.form.new_password.length < 6) {
        this.errorMessage = `${this.$t('help.min_length_is')} 6`;
      } else {
        this.errorMessage = this.$t('error.field_is_required');
      }

      return !Object.values(this.error).includes(true);
    },
    passwordsIdentical() {
      return this.form.new_password === this.confirmedPassword;
    },
  }
}
</script>