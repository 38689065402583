<template>
  <div class="row">
    <div class="col-md-3">
      <div class="card card-custom card-stretch">
        <div class="card-body d-flex flex-column justify-content-center text-center">
          <div class="d-flex flex-column align-items-center">
            <div class="image-input image-input-empty image-input-outline"
                 style="background-image: url(/media/users/blank.png)"
            >
              <div class="image-input-wrapper"></div>
            </div>
          </div>

          <div class="user-name font-size-h3 font-weight-bolder mt-7">
            {{ `${user.name} ${user.last_name}` }}
          </div>
          <div class="user-email text-info">
            {{ user.email }}
          </div>
          <div class="separator separator-solid my-5"/>
          <div>{{ $t('label.your_personal_number') }}:</div>
          <div class="h1 font-weight-boldest text-center">{{ user.billing_id }}</div>
        </div>
      </div>
    </div>

    <div class="col-md-9">
      <div class="card card-custom card-stretch">
        <!--begin::Card header-->
        <div class="card-header card-header-tabs-line nav-tabs-line-3x">
          <!--begin::Toolbar-->
          <div class="card-toolbar">
            <ul class="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-3x">
              <!--begin::Item-->
              <li class="nav-item mr-3">
                <a class="nav-link cursor-pointer"
                   :class="{active: activeTab === 'profile'}"
                   @click="activeTab = 'profile'"
                >
              <span class="nav-icon">
                <span class="svg-icon">
                  <inline-svg src="media/svg/icons/Design/Layers.svg"/>
                </span>
              </span>
                  <span class="nav-text font-size-lg">{{ $t('label.profile') }}</span>
                </a>
              </li>
              <!--end::Item-->
              <!--begin::Item-->
              <li class="nav-item mr-3">
                <a class="nav-link cursor-pointer"
                   :class="{active: activeTab === 'password'}"
                   @click="activeTab = 'password'"
                >
              <span class="nav-icon">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Communication/Shield-user.svg"/>
                </span>
              </span>
                  <span class="nav-text font-size-lg">{{ $t('label.change_password') }}</span>
                </a>
              </li>
              <!--end::Item-->
            </ul>
          </div>
          <!--end::Toolbar-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-0 d-flex flex-column justify-content-center">
          <form class="form" id="kt_form">
            <div class="tab-content">
              <ProfileTab v-show="activeTab === 'profile'" :user="user"/>
              <PasswordTab v-show="activeTab === 'password'"/>
            </div>
          </form>
        </div>
        <!--begin::Card body-->
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PasswordTab from '@/components/profile/PasswordTab';
import ProfileTab from '@/components/profile/ProfileTab';

export default {
  components: {
    PasswordTab,
    ProfileTab
  },
  data: () => ({
    activeTab: 'profile'      // profile / password
  }),
  computed: {
    ...mapGetters({
      user: 'usersStore/USER'
    })
  }
}
</script>
